

.App {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: "UHCSansRegular";
  background-color: #222222;
  background: linear-gradient(78deg, #222222 0%, #444444 100%);
  color: #ffffff;

  /* enforce max width for tablet sized devices */
  min-width: 300px;
  max-width: 900px;
  min-height: 300px;
  margin: 0 auto;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

/* styles for screen reader (usually hidden from display). The sr-only class is used by the VisuallyHidden component. */
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

/* styles for external links */
a.ext-link {
  cursor: pointer;
}
a.ext-link:link,
a.ext-link:visited {
  color: #ffffff;
}
a.ext-link:active,
a.ext-link:hover {
  color: #9e9e9e;
}

.MuiLink-root.ext-link,
.MuiLink-root.ext-link:link,
.MuiLink-root.ext-link:visited {
  color: #ffffff;
}

.MuiLink-root.ext-link:active,
.MuiLink-root.ext-link:hover {
  color: #9e9e9e;
}
