@font-face {
  font-family: "UHCSansBold";
  src: url("../Fonts/uhcsans-bold-webfont.woff") format("woff"),
    url("../Fonts/uhcsans-bold-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "UHCSansSemiBold";
  src: url("../Fonts/UHCSans-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "UHCSansLight";
  src: url("../Fonts/uhcsans-light-webfont.woff") format("woff"),
    url("../Fonts/uhcsans-light-webfont.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "UHCSansMedium";
  src: url("../Fonts/uhcsans-medium-webfont.woff") format("woff"),
    url("../Fonts/uhcsans-medium-webfont.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "UHCSansRegular";
  src: url("../Fonts/uhcsans-regular-webfont.woff") format("woff"),
    url("../Fonts/uhcsans-regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "UHCSansSemiBoldItalic";
  src: url("../Fonts/UHCSans-SemiBoldItalic.woff") format("woff"),
   url("../Fonts/UHCSans-SemiBoldItalic.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}
